<template>

  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <div style="min-height: 95vh;">
    <Header />
    <div v-if="isOffline" class="offline-box">
      <p>Offline Mode</p>
    </div>
    <div v-if="isSyncing" class="syncing-box">
      <p>You're online! Syncing data...</p>
    </div>
    <div class="table-section-instructor">
      <div class="logbook-name">
        <table class="logbook-name-table">
          <tr>
            <td class="InslogbookName">{{ logbookName }}</td>
            <td style="border:none; width: 160px;"><button class="inspdfButton" @click="openSignatureModal">Signature</button></td>
            <td style="width: 160px;"><button @click="showPDFReport"  class="inspdfButton">Generate PDF</button></td>
            <td style="width: 160px;"><button @click="openTraineeProgressModal" class="inspdfButton">Progress</button></td>
          </tr>
        </table>
      </div>
      <div class="ins-pagination-button-container">
        <div class="ins-pagination-container">
          <Pagination :total-pages="totalPages" :per-page="perPage" :current-page="currentPage"
            @page-change="onPageChange" />
        </div>
        <div class="ins-pagination-info">
          Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTasks.length }} Tasks
        </div>
      </div>
      <div class="signoff-buttons">
        <button @click="toggleMultiSignOffMode" class="multi-sign-off-button">
          {{ multiSignOffMode ? 'De-select All' : 'Select All' }}
        </button>
        <button @click="signOffAll" class="multi-sign-off-button">Sign Off All</button>
      </div>
      <table class="data-table-instructor-logbook">
        <thead>
          <tr>
            <th class="task-no">Task No</th>
            <!--<th class="reference">Reference</th>-->
            <th class="module">Module
              <select v-model="selectedmodule">
                <option value="All">All</option>
                <option v-for="module in uniquemodules" :key="module" :value="module">{{ module }}</option>
              </select>
            </th>
            <th class="chapt-id">Chapt No
              <select v-model="selectedChapter">
                <option value="All">All</option>
                <option v-for="chapter in uniqueChapters" :key="chapter" :value="chapter">{{ chapter }}</option>
              </select>
            </th>
            <th class="area" style="width: 3%;">Area <br>
              <select v-model="selectedLocation">
                <option value="All">All</option>
                <option v-for="area in uniqueAreas" :key="area" :value="area">{{ area }}</option>
              </select>
            </th>
            <th class="AMTOSS">AMTOSS<br>
              <select v-model="selectedAMTOSS">
                <option value="All">All</option>
                <option v-for="AMTOSS in unqiueAMTOSS" :key="AMTOSS" :value="AMTOSS">{{ AMTOSS }}</option>
              </select>
            </th>
            <th v-if="isAssessorSelected" class="is_exam">Select for Assessment</th>
            <th v-if="!isAssessorSelected" class="enable">Enable Tasks</th>
            <th class="activity">Activity</th>
            <th class="completed">
              <div v-if="!isAssessorSelected">
                Completed
                <select v-model="selectedCompleted" style="width:90%">
                  <option value="All tasks">All tasks</option>
                  <option value="completed tasks">Completed tasks</option>
                  <option value="not completed tasks">Incomplete tasks</option>
                  <option value="enabled tasks">Enabled tasks</option>
                  <option value="examinable tasks">Examinable tasks</option>
                </select>
              </div>
              <div v-else style="width: 12%">
                <!-- Display a static text or nothing if only completed tasks are to be shown -->
                Completed Tasks
              </div>
            </th>
            <th class="inssign">Inst. Sign Off
              <select v-model="selectedSignOff" style="width:90%">
                <option value="All tasks">All tasks</option>
                <option value="signed off tasks">Signed Off</option>
                <option value="not signed off tasks">Not Signed Off</option>
              </select>
            </th>
            <th v-if="!isAssessorSelected" class="publicnotes">Public Notes</th>
            <th v-if="!isAssessorSelected" class="privatenotes">Private Notes</th>
            <th class="assess" style="width: 2%">Assess</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(group, chaptNo) in paginatedTasks" :key="chaptNo">
    <!-- Row for the Chapter Name -->
    <tr class="chapter-row">
      <td colspan="100%" class="chapter-name-cell">{{ group.name }}</td>
    </tr>
          <tr v-for="(task, index) in group.tasks" :key="index">
            <td @click="openInsTaskLongFormModal(task)" style="color: blue; text-decoration: underline;">{{ task.task_no }}
            </td>
            <!--<td>{{ task.reference }}</td>-->
            <td>{{ task.module }}</td>
            <td>{{ task.chapt_no }}</td>
            <td>{{ task.area }}</td>
            <td>{{ task.AMTOSS }}</td>
            <td v-if="isAssessorSelected">
              <input type="checkbox" v-model="task.being_assessed" @change="toggleBeingAssessed(task)"
                :disabled="task.is_assessed">
            </td>
            <!-- <td v-else>
              {{ task.is_exam ? 'Yes' : 'No' }}
            </td> -->
            <!-- <td>
              <input type="checkbox" v-model="task.is_exam" @change="toggleIsExam(task)" class="custom-checkbox"
                id="center-icons">
            </td> -->
            <td v-if="!isAssessorSelected">
              <input type="checkbox" v-model="task.enabled" @change="toggleEnabled(task)" :disabled="task.completed">
            </td>
            <!-- <td @click="openTraineeAnswerbookModal(task)"
              :class="{ 'Description-data': task.is_exam, 'Other-description': !(task.is_exam) }">
              {{ task.activity }}
            </td> -->
            <td @click="openTraineeAnswerbookModal(task)">
              <div :style="{ color: task.is_assessed ? 'green' : 'blue' }">
                {{ task.activity }}
              </div>
              <div v-if="task.is_exam" :style="{ color: task.is_assessed ? 'green' : 'red' }">
                <b>{{ task.is_assessed ? '*Assessed' : (task.being_assessed ? '*Selected for Assessment' :
      '*Examinable') }}</b>
              </div>
            </td>
            <td>
  <div>{{ task.completed }}</div>
  <p v-if="task.se_so" class="se_so signoff-box tick-container">
    <i class="fas fa-check"></i> SE Signed Off
    <span class="timestamp" v-if="task.se_so">{{ task.se_so }}</span>
  </p>
  <div v-if="task.t_signature_id" class="signature-container" style="max-height: 40px;">
    <img :src="findSignatureUrl(task.t_signature_id)" class="signature-image" alt="Signature">
  </div>
</td>
            <td v-if="multiSignOffMode && task.se_so && !task.ins_so">
              <button class="instructor_signoff" :class="{ 'disabled-signoff': !task.completed }"
              :disabled="!task.completed" @click.prevent="confirmSignOff(task)">SIGN OFF</button>
              <input type="checkbox" v-if="task.completed && !task.ins_so" v-model="task.selectedForSignOff"
                @change="toggleSignOff(task)" />
            </td>
            <td v-else>
              <div v-if="task.ins_so">
                {{ task.ins_so }}
                <div v-if="task.ins_signature_id" class="signature-container" style="max-height: 40px;">
      <img :src="findInstSignatureUrl(task.ins_signature_id)" class="signature-image" alt="Signature">
    </div>
              </div>
              <div v-else>
                <button v-if="!isAssessorSelected" class="instructor_signoff"
                  :class="{ 'disabled-signoff': !task.se_so }" :disabled=!task.se_so
                  @click.prevent="confirmSignOff(task)">SIGN OFF</button>
              </div>
            </td>
            <td v-if="!isAssessorSelected">
              <button class="notesButton" @click="openEditor(task.task_id, true)">
                <i :class="{
      'fas fa-sticky-note': true,
      'icon-green': publicNotes[task.task_id] && publicNotes[task.task_id].trim() !== '',
      'icon-grey': !publicNotes[task.task_id] || publicNotes[task.task_id].trim() === ''
    }"></i>
              </button>
            </td>
            <td v-if="!isAssessorSelected">
              <button class="notesButton" @click="openEditor(task.task_id)">
                <i :class="{
      'fas fa-sticky-note': true,
      'icon-green': privateNotes[task.task_id] && privateNotes[task.task_id].trim() !== '',
      'icon-grey': !privateNotes[task.task_id] || privateNotes[task.task_id].trim() === ''
    }"></i>
              </button>
            </td>
            <td>
              <div v-if="task.is_assessed">
                <a style="text-decoration: underline; color: blue; cursor: pointer;" @click="openAssessmentModal(task)">View Results</a>
              </div>
              <div v-if="task.being_assessed && !task.is_assessed && isAssessorSelected">
                <a @click="openAssessmentModal(task)"
                  style="text-decoration: underline; color: blue; cursor: pointer;">Assess Trainee</a>
              </div>
            </td>
          </tr>
        </template>
          <tr v-if="paginatedTasks.length === 0">
          <td colspan="100" style="text-align: center;">No Matching Tasks</td>
        </tr>
        </tbody>
      </table>
      <div class="evaluationSection">
        <!-- <button class="evaluationButton" @click="openDocModal()">Document Reference</button> -->
        <!-- <button class="evaluationButton" @click="toggleSignatureModal">Add Digital Signature</button> -->

        <button class="evaluationButton" @click="openEvaluationModal()">Evaluation Sheet</button>
      </div>
      <QuillEditor v-if="showQuillEditor" :task-id="currentTaskId" :initialContent="getCurrentTaskNotes"
        :displayTaskId="currentTaskId" @saveNotes="closeQuillEditor">
      </QuillEditor>
      <instroubleshootModal v-if="showTroubleshootModal" :task-id="currentTaskId" :logbook-id="logbookId"
        :troubleshoot="currentTroubleshoot" :task-explanation="currentTaskExplanation" @close="closeTroubleshootModal">
      </instroubleshootModal>
      <TraineeAnswerbookModal v-if="showTraineeAnswerbookModal" :task-id="currentTaskId" :logbook-id="logbookId"
        :task-explanation="currentTaskExplanation" @close="closeTraineeAnswerbookModal"></TraineeAnswerbookModal>
        <InsTaskLongFormModal v-if="showInsTaskLongFormModal" :task="currentTask" :logbook-id="logbookId"
        @close="closeInsTaskLongFormModal"></InsTaskLongFormModal>
        <TraineeProgressModal v-if="showTraineeProgressModal" :logbook-id="logbookId" :merged-tasks="mergedTasks"
        @close="closeTraineeProgressModal"></TraineeProgressModal>
      <evaluation-form-modal v-if="showEvaluationFormModal" :tasks-count="tasksCount" :completed-tasks="tasksCompleted"
        :evaluation-id="evaluationId" :logbook-id="logbookId" :fetched-data="fetchedEvaluationData"
        @close="closeEvaluationModal"></evaluation-form-modal>
      <task-assessment-modal v-if="showTaskAssessmentModal" :task-id="currentTaskId" :tasks-count="tasksCount"
        :completed-tasks="tasksCompleted" :assessment-id="taskAssessmentId" :logbook-id="logbookId"
        :fetched-data="fetchedTaskAssessmentData" @close="closeAssessmentModal"></task-assessment-modal>
        <signature-modal v-if="showSignatureModal" @close="closeSignatureModal"></signature-modal>

    </div>
  </div>
</template>

<script>
import Header from './InstructorHeader.vue';
import axios from 'axios';
import './InstructorLogbook.css';
import Pagination from '@/components/Examiner/TablePagination.vue';
import QuillEditor from '@/components/notes/quill.vue';
import LogbookReport from './InstructorLogbookReport.vue';
import instroubleshootModal from '@/components/Instructor/InsTroubleshootModal.vue';
import TraineeAnswerbookModal from '@/components/Instructor/InsTraineeAnswerbookModal.vue';
import InsTaskLongFormModal from '@/components/Instructor/InsTaskLongFormModal.vue';
import TraineeProgressModal from '@/components/Trainee/TraineeProgressModal.vue';
import EvaluationFormModal from '@/components/Instructor/EvaluationFormModal.vue';
import TaskAssessmentModal from '@/components/Instructor/TaskAssessmentModal.vue';
import SignatureModal from '@/components/Examiner/SignatureModal.vue';


import Localbase from 'localbase';
let db = new Localbase('myDatabase');

export default {
  name: 'InstructorLogbook',
  components: {
    Pagination,
    Header,
    QuillEditor,
    instroubleshootModal,
    TraineeAnswerbookModal,
    InsTaskLongFormModal,
    TraineeProgressModal,
    EvaluationFormModal,
    TaskAssessmentModal,
    SignatureModal,
  },
  data() {
    return {
      logbooks: [],
      tasks: [],
      logbookName: '',
      productId: localStorage.getItem('productId') || null,
      logbookId: localStorage.getItem('logbook_id'),
      mergedTasks: [],
      editorContent: '',
      showQuillEditor: false,
      showTroubleshootModal: false,
      showTraineeAnswerbookModal: false,
      showInsTaskLongFormModal: false,
      showTraineeProgressModal: false,
      showEvaluationFormModal: false,
      showTaskAssessmentModal: false,
      showDocModal: false,
      fetchedEvaluationData: null,
      fetchedTaskAssessmentData: null,
      showSignatureModal: false,
      currentTaskId: null,
      tasksCount: 0,
      privateNotes: {},
      publicNotes: {},
      notesContent: '',
      showDropdown: false,
      selectedAMTOSS: 'All',
      selectedCompleted: 'All tasks',
      selectedLevel: 'All',
      selectedmodule: 'All',
      selectedCategory: 'All',
      selectedChapter: 'All',
      selectedisexam: 'All',
      selectedSignOff: 'All tasks',
      selectedLocation: 'All',
      selectedArea: 'All',
      isSyncing: false,
      isOffline: !navigator.onLine,
      selectedTasks: [],
      multiSignOffMode: false,
      filteredTasksLength: 0,
      currentPage: 1,
      perPage: 10,
      token: localStorage.getItem('token'),
      signatureId: '',
      instSignatureId: '',
      user_id: null,
      instructorSignatures: {},
      traineeSignatureUrl: null,
      signatureUrl: null,
      signatures: [],
      currentTask: {},
      chapters: [],
    };
  },
  computed: {
    getCurrentTaskNotes() {
      const noteSource = this.isNotePublic ? this.publicNotes : this.privateNotes;
      return noteSource[this.currentTaskId] || '';
    },
    filteredTasks() {
      return this.mergedTasks.filter(task => {
        this.currentPage = 1;
        const amtossMatches = this.selectedAMTOSS === 'All' || task.AMTOSS === this.selectedAMTOSS;
        const completedMatches =
          this.selectedCompleted === 'All tasks' ||
          (this.selectedCompleted === 'completed tasks' && task.completed !== null) ||
          (this.selectedCompleted === 'not completed tasks' && !task.completed) ||
          (this.selectedCompleted === 'enabled tasks' && task.enabled && !task.completed) ||
          (this.selectedCompleted === 'examinable tasks' && task.is_exam);

        const signedOffMatches =
          this.selectedSignOff === 'All tasks' ||
          (this.selectedSignOff === 'signed off tasks' && task.ins_so !== null) ||
          (this.selectedSignOff === 'not signed off tasks' && task.ins_so === null);
        const levelMatches = this.selectedLevel === 'All' || task.level === parseInt(this.selectedLevel);
        const moduleMatches = this.selectedmodule === 'All' || task.module === this.selectedmodule;
        const chapterMatches = this.selectedChapter === 'All' || task.chapt_no === this.selectedChapter;
        const categoryMatches = this.selectedCategory === 'All' || task.category === this.selectedCategory;
        const isexamMatches = this.selectedisexam === 'All' || task.is_exam === this.selectedisexam;
        const areaMatches = this.selectedArea === 'All' || task.area === this.selectedArea;

        return amtossMatches && completedMatches && signedOffMatches && levelMatches && moduleMatches && isexamMatches && chapterMatches && categoryMatches && areaMatches;
      });
    },
    unqiueAMTOSS() {
      const amtoss = this.tasks.map(task => task.AMTOSS);
      return [...new Set(amtoss)];
    },
    uniqueisexam() {
      const isexams = this.tasks.map(task => task.is_exam);
      return [...new Set(isexams)];
    },
    groupedTasks() {
  return Object.entries(
    this.filteredTasks.reduce((groups, task) => {
      const chapterName = this.getChapterName(task.chapt_no);
      if (!groups[task.chapt_no]) {
        groups[task.chapt_no] = { name: chapterName, tasks: [] };
      }
      groups[task.chapt_no].tasks.push(task);
      return groups;
    }, {})
  )
    .map(([chaptNo, group]) => ({
      chaptNo,
      ...group,
    }))
    .sort((a, b) => parseInt(a.chaptNo, 10) - parseInt(b.chaptNo, 10)); // Sort numerically by chapter number
},
paginatedTasks() {
  // Paginate the filtered tasks before grouping them by chapter
  const start = this.startIndex;
  const end = this.endIndex;
  // Get the paginated filtered tasks
  const paginatedFilteredTasks = this.filteredTasks.slice(start, end);
  // Now, group the tasks by chapter
  return Object.entries(
    paginatedFilteredTasks.reduce((groups, task) => {
      const chapterName = this.getChapterName(task.chapt_no);
      if (!groups[task.chapt_no]) {
        groups[task.chapt_no] = { name: chapterName, tasks: [] };
      }
      groups[task.chapt_no].tasks.push(task);
      return groups;
    }, {})
  )
    .map(([chaptNo, group]) => ({
      chaptNo,
      ...group,
    }))
    .sort((a, b) => parseInt(a.chaptNo, 10) - parseInt(b.chaptNo, 10)); // Sort numerically by chapter number
},
    uniqueChapters() {
      const chapters = this.tasks.map(task => task.chapt_no);
      return [...new Set(chapters)]; // This will remove any duplicates and return unique values.
    },
    uniqueLocations() {
      const locations = this.tasks.map(task => task.location);
      return [...new Set(locations)];
    },
    uniqueLevels() {
      const levels = this.tasks.map(task => task.level);
      return [...new Set(levels)];
    },
    uniqueAreas() {
      const areas = this.tasks.map(task => task.area);
      return [...new Set(areas)];
    },
    uniquemodules() {
      const modules = this.tasks.map(task => task.module);
      return [...new Set(modules)];
    },
    uniquecategories() {
      const categories = this.tasks.map(task => task.category);
      return [...new Set(categories)];
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.perPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTasks.length ? end : this.filteredTasks.length;
    },
    localIsAssessorSelected: {
      get() {
        return this.isAssessorSelected;
      },
      set(newValue) {
        this.$emit('update:isAssessorSelected', newValue);
      }
    }
  },

  methods: {
    getChapterName(chapt_no) {
  const chapter = this.chapters.find((ch) => ch.chapt_no === chapt_no.toString());
  return chapter ? `Chapter ${chapt_no}: ${chapter.name}` : `Chapter ${chapt_no}`;
},
    async addSigToTasks() {
  try {
    const logbookId = this.logbookId;
    const tasksToUpdate = [];

    // Filter tasks that need signature
    const filteredSigTasks = this.mergedTasks.filter(task => {
      return task.completed !== null && task.ins_signature_id === null;
    });

    // Create an array of tasks to update
    for (const task of filteredSigTasks) {
      tasksToUpdate.push({
        logbook_id: parseInt(logbookId),
        task_id: parseInt(task.task_id),
        ins_signature_id: parseInt(localStorage.getItem('inst_sign_id'))
      });
    }

    console.log('inst_sign_id');
    console.log(parseInt(localStorage.getItem('inst_sign_id')));

    if (tasksToUpdate.length === 0) {
      console.log('No tasks require signature.');
      return;
    }

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    // Make an Axios POST request to the batchUpdate API endpoint
    const response = await axios.post('/api/logbookentry/batch-update', {
      logbook_entries: tasksToUpdate
    }, { headers });

    console.log("Success in updating logbook entries:", response);

    // Fetch updated data after successful batch update
    this.allSignatures();
    this.fetchData();

  } catch (error) {
    console.error('Error updating logbook entries:', error);
  }
},
    findSignatureUrl(signatureId) {
    const signature = this.signatures.find(sig => sig.id === signatureId);
    if (signature && signature.user_image) {
      return `data:image/jpeg;base64,${signature.user_image}`;
    } else {
      return null;
    }
  },
  findInstSignatureUrl(instSignatureId) {
    const signature = this.signatures.find(sig => sig.id === instSignatureId);
    if (signature && signature.user_image) {
      return `data:image/jpeg;base64,${signature.user_image}`;
    } else {
      return null;
    }
  },
    hasNotes(taskId) {
      return this.publicNotes[taskId] || this.privateNotes[taskId];
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    toggleSignatureModal() {
      this.showSignatureModal = true;
    },
    openDocModal() {
      this.showDocModal = true;
    },
    closeDocModal() {
      this.showDocModal = false;
    },
    async handleDigitalSignature() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const instructorId = localStorage.getItem('user_id');

        // Check if a digital signature already exists
        const checkResponse = await axios.get(`/api/check-signature?instructorId=${instructorId}`, { headers });
        let proceedWithSigning = false;

        if (checkResponse.data.isSigned) {
          // Confirm overwrite if a signature already exists
          proceedWithSigning = window.confirm("A digital signature already exists. Do you want to overwrite it?");
        } else {
          // Confirm creating a new signature if none exists
          proceedWithSigning = window.confirm("Do you want to create a new signature? This action will record the current timestamp.");
        }

        if (!proceedWithSigning) {
          return; // Do nothing if the user cancels
        }

        // Post request to create or update the digital signature
        const endpoint = checkResponse.data.isSigned ? '/api/update-signature' : '/api/sign-pdf';
        const response = await axios.post(endpoint, { instructorId }, { headers });

        console.log('Digital signature response:', response.data);
        alert('New digital signature recorded');

      } catch (error) {
        console.error('Error in digital signature process:', error);
        // Handle the error (e.g., show an error message)
      }
    },
    async toggleEnabled(task) {
      if (!task.enabled) {
        const confirmation = window.confirm('Are you sure you want to disable this task?');
        if (!confirmation) {
          task.enabled = true;
          return;
        }
      }
      const logbookId = localStorage.getItem('logbook_id');
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        await axios.get(`/api/logbookentry/${logbookId}/${task.task_id}`, { headers });
        // If the task already exists, make a PUT request
        await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
          enabled: task.enabled, // Update the 'enabled' property
          is_submitted: false
        }, { headers });
        // Update the local 'enabled' property
        const matchingTask = this.mergedTasks.find(t => t.task_id === task.task_id);
        if (matchingTask) {
          matchingTask.enabled = task.enabled;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // If the error is a 404, proceed to the POST request
          try {
            const logbookId = localStorage.getItem('logbook_id');
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };
            await axios.post(`/api/logbookentry/`, {
              logbook_id: logbookId,
              task_id: task.task_id,
              enabled: task.enabled, // Update the 'enabled' property
              is_submitted: false
            }, { headers });
            // Update the local 'enabled' property
            const matchingTask = this.mergedTasks.find(t => t.task_id === task.task_id);
            if (matchingTask) {
              matchingTask.enabled = task.enabled;
            }
          } catch (postError) {
            console.error("Error creating new task:", postError);
          }
        } else {
          console.error("Error updating 'enabled' property:", error);
        }
      }
    },
    async toggleIsExam(task) {
      if (!task.is_exam) {
        const confirmation = window.confirm('Are you sure you want to deselect the option?');
        if (!confirmation) {
          task.is_exam = true; // If the user cancels the confirmation, keep the checkbox checked
          return;
        }
      }
      // Toggle the is_exam value
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log("task_id", task.task_id);
        console.log("is_exam", task.is_exam)
        await axios.put(`/api/tasks/${task.task_id}`, {
          is_exam: task.is_exam // Send the value to the backend
        }, { headers });
      } catch (error) {
        console.error("Error updating 'is_exam' property:", error);
        // If the update fails, revert the checkbox state
        task.is_exam = !task.is_exam;
      }
    },
    async toggleBeingAssessed(task) {
      if (!task.being_assessed) {
        const confirmation = window.confirm('Are you sure you want to deselect the option?');
        if (!confirmation) {
          task.being_assessed = true; // If the user cancels the confirmation, keep the checkbox checked
          return;
        }
      }
      // Toggle the is_exam value
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbook_id = localStorage.getItem('logbook_id');
        console.log("task_id", task.task_id);
        console.log("being_assessed", task.being_assessed);

        await axios.put(`/api/logbookentry/${logbook_id}/${task.task_id}`, {
          being_assessed: task.being_assessed // Send the value to the backend
        }, { headers });
      } catch (error) {
        console.error("Error updating 'being_assessed' property:", error);
        // If the update fails, revert the checkbox state
        task.being_assessed = !task.being_assessed;
      }
    },
    async setTimestamp(task) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      task.ins_so = formattedDate;
      task.isReadOnly = true;

      const logbookId = localStorage.getItem('logbook_id');
      const userId = localStorage.getItem('user_id');  // Retrieve user_id from localStorage

      if (!navigator.onLine) {
        // Save to Localbase when offline
        db.collection('InsCompletedTasks').doc(String(task.task_id)).set({
          taskId: String(task.task_id),  // Convert task_id to string
          ins_so: formattedDate,
          logbookId: logbookId,
          userId: userId  // Also save the user_id offline
        }).then(() => {
          console.log('Task saved offline');
        });
      } else {
        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
            ins_so: formattedDate,
            instructor_id: userId,
            ins_signature_id: parseInt(localStorage.getItem('inst_sign_id')),
          }, { headers });
          console.log("Success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
          if (parseInt(localStorage.getItem('inst_sign_id'))) {
          this.allSignatures();
          const entryResult = await axios.get(`/api/logbookentry/${logbookId}`, { headers });
          this.logbookEntries = entryResult.data.tasks; 
          this.mergeData();
          }
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
      }
    },
    async syncData() {
      // Check if there's an internet connection
      if (navigator.onLine) {
        this.isSyncing = true;

        // Synchronize completed tasks
        const completedTasksToSync = await db.collection('InsCompletedTasks').get();
        await this.syncCompletedTasks(completedTasksToSync);

        // Synchronize enabled status changes
        const enabledStatusToSync = await db.collection('TaskEnabledStatus').get();
        await this.syncEnabledStatusChanges(enabledStatusToSync);

        this.isSyncing = false;

        // Reload the page to reflect the synced data
        location.reload();
      } else {
        console.log("No internet connection. Data sync postponed.");
      }
    },

    async syncCompletedTasks(tasksToSync) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      for (const task of tasksToSync) {
        try {
          await axios.put(`/api/logbookentry/${task.logbookId}/${task.taskId}`, {
            ins_so: task.ins_so
          }, { headers });
          await db.collection('InsCompletedTasks').doc(task.taskId).delete();
        } catch (error) {
          console.error('Error syncing completed task:', error);
        }
      }
    },

    async syncEnabledStatusChanges(tasksToSync) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      for (const task of tasksToSync) {
        try {
          await axios.post(`/api/logbookentry/`, {
            logbook_id: task.logbookId,
            task_id: task.taskId,
            enabled: task.enabled,
            being_assessed: task.being_assessed,
            is_assessed: task.is_assessed,
            is_submitted: false,
          }, { headers });
          await db.collection('TaskEnabledStatus').doc(task.taskId).delete();
        } catch (error) {
          console.error('Error syncing enabled status:', error);
        }
      }
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbookId = localStorage.getItem('logbook_id');

        const [logbookResponse, inslogbookEntryResponse, tasksResponse] = await Promise.all([
          axios.get(`/api/logbooks/${logbookId}`, { headers }),
          axios.get(`/api/logbookentry/${logbookId}`, { headers }),
          axios.get(`/api/${logbookId}/tasks`, { headers })
        ]);
        this.logbookName = logbookResponse.data.name;
        this.tasks = tasksResponse.data.tasks;
        this.logbookEntries = inslogbookEntryResponse.data.tasks;

        this.mergeData();
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    mergeData() {
      this.mergedTasks = this.tasks.map(task => {
        const matchingEntry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
        return {
          ...task,
          completed: matchingEntry ? matchingEntry.completed : null,
          ins_so: matchingEntry ? matchingEntry.ins_so : null,
          se_so: matchingEntry ? matchingEntry.se_so : null,
          enabled: matchingEntry ? matchingEntry.enabled === 1 : 0,
          is_exam: task.is_exam !== undefined ? !!task.is_exam : false,
          being_assessed: matchingEntry ? matchingEntry.being_assessed === 1 : 0,
          is_assessed: matchingEntry ? matchingEntry.is_assessed === 1 : 0,
          instructor_id: matchingEntry ? matchingEntry.instructor_id : null,
          t_signature_id: matchingEntry ? matchingEntry.t_signature_id : null,
          ins_signature_id: matchingEntry ? matchingEntry.ins_signature_id : null,
          ac_reg: matchingEntry ? matchingEntry.ac_reg : null,
          jobcard: matchingEntry ? matchingEntry.jobcard : null,
          amm_ref: matchingEntry ? matchingEntry.amm_ref : null,
          proof_data: matchingEntry ? matchingEntry.proof_data : null,
        };
      });
    },

    async fetchAllSignatures() {
      const idsRaw = localStorage.getItem('instructorIds');
      console.log("Raw instructor IDs from localStorage:", idsRaw);

      let instructorIds;
      try {
        instructorIds = JSON.parse(idsRaw);
      } catch (error) {
        console.error("Error parsing instructorIds from localStorage:", error);
        instructorIds = [];
      }

      if (!Array.isArray(instructorIds)) {
        console.error("Parsed instructorIds is not an array:", instructorIds);
        instructorIds = [];
      }

      console.log("Parsed instructor IDs:", instructorIds);
      instructorIds.forEach(instructorId => {
        if (instructorId === undefined) {
          console.error("Skipping fetch for undefined instructorId");
        } else {
          this.fetchSignature(instructorId);
        }
      });
    },

    async fetchSignature(instructorId) {
      // if (!this.user_id || this.instructorSignatures[instructorId]) {
      //   return; // Exit if no user_id or signature already fetched
      // }
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const sigResponse = await axios.get(`/api/store_image/fetch_image/${instructorId}`, {
          responseType: 'blob',
          headers: headers
        });
        const url = window.URL.createObjectURL(new Blob([sigResponse.data]));
        this.instructorSignatures[instructorId] = url;
      } catch (error) {
        console.error(`Error fetching signature for instructor ${instructorId}:`, error);
        this.instructorSignatures[instructorId] = null;
      }
    },

    shouldShowSignOffButton(task) {
      return (
        !task.complete && // Check if complete is empty (not signed off)
        task.work_order_no !== null &&
        task.work_order_no.toString().trim() !== '' &&
        task.task_detail !== null &&
        task.task_detail.toString().trim() !== '' &&
        task.category !== null &&
        task.category.toString().trim() !== '' &&
        task.ATA !== null &&
        task.ATA.toString().trim() !== ''
      );
    },
    async fetchPublicNotesForTask(taskId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = `/api/instructorPublicNotes/${taskId}`;

      axios.get(endpoint, { headers })
        .then(response => {
          if (response.data && response.data.content) {
            this.publicNotes[taskId] = response.data.content;
          } else {
            this.publicNotes[taskId] = '';
          }
        })
        .catch(error => {
          console.error(`Error fetching public notes for task ${taskId}:`, error);
        });
    },

    async fetchPrivateNotesForTask(taskId) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = `/api/instructorPrivateNotes/${taskId}`;

      axios.get(endpoint, { headers })
        .then(response => {
          if (response.data && response.data.content) {
            this.privateNotes[taskId] = response.data.content;
          } else {
            this.privateNotes[taskId] = '';
          }
        })
        .catch(error => {
          console.error(`Error fetching private notes for task ${taskId}:`, error);
        });
    },

    async savePublicNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      const postEndpoint = '/api/instructor-public-notes';
      const putEndpoint = `/api/instructorPublicNotes/${taskId}`;

      if (this.publicNotes[taskId]) {
        axios.put(putEndpoint, { content }, { headers })
          .then(response => {
            this.publicNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error updating public note:', error);
          });
      } else {
        const userId = localStorage.getItem('instructor_id');
        axios.post(postEndpoint, { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.publicNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error creating public note:', error);
          });
      }
    },

    async savePrivateNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      const postEndpoint = '/api/instructor-private-notes';
      const putEndpoint = `/api/instructorPrivateNotes/${taskId}`;

      if (this.privateNotes[taskId]) {
        axios.put(putEndpoint, { content }, { headers })
          .then(response => {
            this.privateNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error updating private note:', error);
          });
      } else {
        const userId = localStorage.getItem('instructor_id');
        axios.post(postEndpoint, { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.privateNotes[taskId] = response.data.content;
          })
          .catch(error => {
            console.error('Error creating private note:', error);
          });
      }
    },

    async openEditor(taskId, isPublic = false) {
      // Clear the note for the current task ID first
      if (isPublic) {
        if (!this.publicNotes) this.publicNotes = {};
        this.publicNotes[this.currentTaskId] = '';  // Clear the previous public note
      } else {
        if (!this.privateNotes) this.privateNotes = {};
        this.privateNotes[this.currentTaskId] = '';  // Clear the previous private note
      }

      // Update the current task ID
      this.currentTaskId = taskId;
      this.isNotePublic = isPublic;  // Set the note type
      // Fetch the appropriate note for the new task ID
      if (isPublic) {
        await this.fetchPublicNotesForTask(taskId);
      } else {
        await this.fetchPrivateNotesForTask(taskId);
      }

      this.showQuillEditor = true;
    },

    closeQuillEditor(payload) {
      if (this.isNotePublic) {
        this.savePublicNotes(payload.taskId, payload.content);
        this.fetchAllPublicNotes();
        this.publicNotes[this.currentTaskId] = '';  // Clear the public note
      } else {
        this.savePrivateNotes(payload.taskId, payload.content);
        this.fetchAllPrivateNotes();
        this.privateNotes[this.currentTaskId] = '';  // Clear the private note
      }
      this.showQuillEditor = false;
    },
    async fetchAllPublicNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      axios.get(`/api/instructor-public-notes`, { headers })
        .then(response => {
          console.log('Fetched all notes:', response.data);
          // Assuming response.data is an array of notes
          response.data.forEach(note => {
            this.publicNotes[note.task_id] = note.content;
          });
        })
        .catch(error => {
          console.error("Error fetching all public notes:", error);
        });
    },
    async fetchAllPrivateNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      axios.get(`/api/instructor-private-notes`, { headers })
        .then(response => {
          console.log('Fetched all notes:', response.data);
          // Assuming response.data is an array of notes
          response.data.forEach(note => {
            this.privateNotes[note.task_id] = note.content;
          });
        })
        .catch(error => {
          console.error("Error fetching all private notes:", error);
        });
    },
    isTaskVisible(task) {
      if (this.taskFilter === 'all') return true;
      if (this.taskFilter === 'completed' && task.completed) return true;
      if (this.taskFilter === 'uncompleted' && !task.completed) return true;
      return false;
    },
    goHome() {
      this.$router.push({ name: 'InstructorHome' }) // Go back to the home page
    },
    confirmSignOff(task) {
      if (confirm('Are you sure you want to sign off?')) {
        this.setTimestamp(task);
        this.fetchSignature();
      }
    },
    allSignatures() {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  axios.get(`/api/books`, { headers })
    .then(response => {
      // Access the data from the response
      const responseData = response.data;
      
      // Do something with the data, for example, assign it to a variable or log it
      this.signatures = responseData;
      console.log('all');
      console.log(responseData);
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      console.error('Error fetching signatures:', error);
    });
    this.fetchCurrentSignatureId();
},
async fetchCurrentSignatureId() {
  try {
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  const response = await axios.get(`/api/current-signature/${this.user_id}`, { headers })

  localStorage.setItem('inst_sign_id', response.data.id);
  this.signatureId = localStorage.getItem('inst_sign_id');
  } catch (error) {
        console.error('An error occurred while fetching signature id:', error);
}
},
fetchTraineeSignature(id) {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/signatures/${id}`, {
        responseType: 'blob',
        headers: headers
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.signatureUrl = url;
        })
        .catch(error => {
          if (error.response) {
            console.error('Error status:', error.response.status);
            console.error('Error data:', error.response.data);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        });
    },
    async signOffAll() {
      if (this.selectedTasks.length === 0) {
        alert('No tasks selected for sign-off.');
        return;
      }
      if (confirm('Are you sure you want to sign off on ' + this.selectedTasks.length + ' selected tasks?')) {
        try {
          const currentDate = new Date();
          const formattedDate = this.formatDate(currentDate);
          const logbookId = localStorage.getItem('logbook_id');
          const tasksToUpdate = this.selectedTasks.map(task => {
            return {
              logbook_id: parseInt(logbookId),
              task_id: parseInt(task.task_id),
              ins_so: formattedDate,
              instructor_id: parseInt(localStorage.getItem('user_id')),
              ins_signature_id: parseInt(localStorage.getItem('inst_sign_id')),
            };
          });
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          // Make an Axios POST request to the batchUpdate API endpoint
          await axios.post('/api/logbookentry/batch-update', {
            logbook_entries: tasksToUpdate
          }, { headers });
          // Clear the selectedTasks array after successful sign-off
          this.selectedTasks = [];
          window.location.reload();
        } catch (error) {
          console.error('Error signing off tasks:', error.response.data.message);
          // You can handle the error here, e.g., show an error message to the user
          alert('Error signing off tasks: ' + error.response.data.message);
        }
      }
    },
    toggleMultiSignOffMode() {
      if (this.multiSignOffMode) {
        // If currently in Multi-Sign Off mode, exit it
        this.multiSignOffMode = false;
        this.selectedTasks = [];
      } else {
        // If not in Multi-Sign Off mode, switch to Select All mode
        this.multiSignOffMode = true;
        // Perform the "Select All" action here
        this.toggleAllTasksSelection();
      }
    },
    toggleSignOff(task) {
      if (task.selectedForSignOff) {
        this.selectedTasks.push(task);
      } else {
        const index = this.selectedTasks.findIndex((selectedTask) => selectedTask.task_id === task.task_id);
        if (index !== -1) {
          this.selectedTasks.splice(index, 1);
        }
      }
    },
    toggleAllTasksSelection() {
      this.selectedTasks = [];

      this.filteredTasks.forEach(task => {
        if (task.se_so && !task.ins_so) {
          task.selectedForSignOff = true;
          this.selectedTasks.push(task);
          console.log(this.selectedTasks);
        }
      });

      if (this.selectedTasks.length === 0) {
        alert("All tasks have been already signed off.");
        this.multiSignOffMode = false;
      }
    },
    async createPDFReport() {
      const logbookName = this.logbookName;
      LogbookReport.methods.generatePDF(this.filteredTasks, logbookName);
    },
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },
    openTroubleshootModal(task) {
      this.showTroubleshootModal = true;
      const entry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
      this.currentTaskExplanation = task.task_explanation;
      if (entry) {
        this.currentTaskId = task.task_id;  // Make sure 'task_id' is the correct property name
        this.currentTroubleshoot = entry.troubleshoot ? entry.troubleshoot.replace(/<br\s*\/?>/gi, '\n') : '';
      } else {
        console.error('No logbook entry found for task id:', task.task_id);
        this.currentTroubleshoot = '';  // Set to empty string if not found
      }
    },
    closeTroubleshootModal() {
      this.showTroubleshootModal = false;
    },
    openInsTaskLongFormModal(task) {
      this.currentTask = task;
      console.log('this.currentTask');
      console.log(this.currentTask);
      this.showInsTaskLongFormModal = true;
    },
    closeInsTaskLongFormModal() {
      this.showInsTaskLongFormModal = false;
    },
    openTraineeProgressModal() {
      this.showTraineeProgressModal = true;
    },
    closeTraineeProgressModal() {
      this.showTraineeProgressModal = false;
    },
    openTraineeAnswerbookModal(task) {
        this.currentTaskId = task.task_id;
        this.currentTaskExplanation = task.task_explanation;
        this.showTraineeAnswerbookModal = true;
    },
    closeTraineeAnswerbookModal() {
      this.showTraineeAnswerbookModal = false;
    },
    openSignatureModal() {
      this.showSignatureModal = true;
    },
    closeSignatureModal() {
      this.showSignatureModal = false;
      this.addSigToTasks();
    },
    fetchEvaluationData() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;

      axios.get(`/api/evaluations?logbook_id=${logbookId}`, { headers })
        .then(response => {
          const evaluation = response.data[0];
          if (evaluation) {
            this.evaluationId = evaluation.id; // Store the fetched evaluation ID
            this.fetchedEvaluationData = evaluation; // Pass the evaluation data directly
            console.log(this.fetchedEvaluationData)
          }
        })
        .catch(error => {
          console.error('There was an error fetching the evaluation:', error);
        });
    },
    fetchTaskAssessmentData() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;
      const taskId = this.currentTaskId;

      axios.get(`/api/task_assessments/${logbookId}/${taskId}`, { headers })
        .then(response => {
          const taskAssessment = response.data;
          console.log(response.data);
          if (taskAssessment) {
            this.taskAssessmentId = taskAssessment.id; // Store the fetched Task Assessment ID
            this.fetchedTaskAssessmentData = taskAssessment; // Pass the task assessment data directly
            console.log(this.fetchedTaskAssessmentData)
          }
        })
        .catch(error => {
          console.error('There was an error fetching the evaluation:', error);
          this.fetchedTaskEvaluationData = '';
          return;
        });
    },

    openEvaluationModal() {
      this.showEvaluationFormModal = true;
      this.tasksCount = this.tasks.length;
      this.tasksCompleted = this.logbookEntries?.completed?.length || 0;
      this.fetchEvaluationData();
    },
    closeEvaluationModal() {
      this.showEvaluationFormModal = false;
      this.fetchEvaluationData();
    },
    openAssessmentModal(task) {
      this.showTaskAssessmentModal = true;
      this.tasksCount = this.tasks.length;
      this.currentTaskId = task.task_id;
      this.tasksCompleted = this.logbookEntries?.completed?.length || 0;
      this.fetchTaskAssessmentData();
    },
    closeAssessmentModal() {
      this.showTaskAssessmentModal = false;
      this.fetchTaskAssessmentData();
    },
    updateIsAssessorSelected(newValue) {
      this.$emit('update:isAssessorSelected', newValue);
    },
    async createTaskLogbookEntry(task, headers) {
      await axios.post(`/api/logbookentry/`, {
        logbook_id: this.logbookId,
        task_id: task.task_id,
        enabled: true,
        is_submitted: false
      }, { headers });
    },
    async fetchChapters () {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      try {
        const chapterResponse = await axios.get(`/api/chapters`, { headers });
        this.chapters = chapterResponse.data;
        console.log('chapters', this.chapters);
      } catch (error) {
        console.error(`Error fetching data for chapters:`, error);
      }
    },
  },
  async mounted() {
    const isAssessor = localStorage.getItem('isAssessor');
    const isAssessorSelected = JSON.parse(isAssessor);
    this.isAssessorSelected = isAssessorSelected;
    console.log(`Assessor: ${isAssessorSelected}`);
    this.$route.params.logbookId;
    this.$route.params.productId;
    this.$route.params.traineeId;
    window.addEventListener('online', this.syncData);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.fetchData();
    if (!isAssessorSelected) {
      this.fetchAllPrivateNotes();
      this.fetchAllPublicNotes();
    }
    this.fetchAllSignatures();
    this.allSignatures();
    this.fetchChapters();
    if (isAssessorSelected) {
      this.selectedCompleted = 'completed tasks';
      this.selectedisexam = true;
    }
  //   this.loadTasks();
  //   await this.initializeTaskEnabledState();
   },
  beforeMount() {
    window.removeEventListener('online', this.syncData);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
}
</script>

<style scoped>
p.se_so {
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(0, 162, 255);
  font-size: 13px;
}

.icon-green {
  color: rgb(21, 185, 21);
  fill: green;
}

p.se_so {
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(0, 162, 255);
  font-size: 13px;
}

.logbook-name {
  margin-top: 10px;
  margin-bottom: 20px;
}

.signatureButton{
  border-radius: 5px;
  width: 200px;
  background-color: #a52c0d;
  color:white;
  padding:5px;
  font-size: 18px;
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

img {
  width: 100%;
  height: 30%;
  border: 1px solid lightgray;
}

.signatureButton {
  margin-right: 20px;
  float: right;
}

.icon-green {
  color: rgb(21, 185, 21);
  fill: green
}

.signature-container,
.ins-signature-container {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
}

.ins-signature-image,
.signature-image {
  width: 100%;
  height: 100%;
}

.inspdfButton {
  border-radius: 5px;
  width: 150px;
  background-color: #a52c0d;
  color:white;
  padding:5px;
  font-size: 16px;
  border: 1px solid #491204;
  float: right;
}

.inspdfButton:hover {
  background-color: #791e08;
}

.inspdfButton:disabled {
  background-color: #919191;
  border: 1px solid grey;
}

.tick-container {
  position: relative;
  display: inline-block;
}

.timestamp {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute; /* Use absolute positioning relative to the parent */
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  
  /* Positioning the tooltip */
  left: 50%; /* Center horizontally */
  bottom: 125%; /* Place it above the signoff-box */
  transform: translateX(-50%); /* Center the tooltip */
}

.signoff-box:hover .timestamp {
  visibility: visible;
  opacity: 1;
}

.chapter-name-cell {
  background-color: rgba(38, 65, 116, 0.884);
  color: white;
  text-align: center;
  
}
</style>