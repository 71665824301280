<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="products-modal" @click.stop>
      <div class="Trainee-TS-modal-header">
        <h5 class="Trainee-TS-modal-title" style="padding-top: 10px;"><b>SE Sign Off</b> (Task ID: {{
    taskId }})
        </h5>
      </div>
      <div class="modal-content-QR" @click.stop>
        <div id="app" class="bg-secondary">
          <div class="row bg-secondary" style="margin-right: 0px; margin-left: 0px">
            <div class="col-md bg-secondary">
              <div class="card">
                <div class="card-body" style="background: darkgray">
                  <div style="height: 60vh;">
                    <!-- we used vueSignature , so write in form vueSignature -->
                    <qrcode-stream :track="drawOutline"></qrcode-stream>
                  </div>
                  <button @click="closeModal" variant="info" class="m-1">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import axios from 'axios';

export default {
  name: 'QrcodeModal',
  components: {
    QrcodeStream,
  },
  props: {
    taskId: {
      type: [String, Number],
      required: true
    },
    logbookId: {
      type: [String, Number],
      required: true
    },
    onSignedOff: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showQRCode: true,
      logbookEntryUpdated: false,
      qrcodeProcessed: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async drawOutline(decodeData, context) {
  if (this.qrcodeProcessed) {
    return; // Skip execution if already processed
  }

  var points = decodeData[0].cornerPoints.map(point => ({ x: point.x, y: point.y }));
  const rawValue = decodeData[0].rawValue;

  const userIdMatch = rawValue.match(/User ID: (\d+)/);
  const nameMatch = rawValue.match(/Name: ([^,]+)/);
  const emailMatch = rawValue.match(/Email: ([^,]+)/);

  if (!userIdMatch || !nameMatch || !emailMatch) {
    console.error("QR code format is invalid. Missing User ID, Name, or Email.");
    return;
  }

  const userId = userIdMatch[1];  // Extracted User ID

      // Drawing logic here...
      context.lineWidth = 10;
    context.strokeStyle = 'green';
    context.beginPath();
    context.moveTo(points[0].x, points[0].y);
    points.forEach(point => {
      context.lineTo(point.x, point.y);
    });
    context.lineTo(points[0].x, points[0].y);
    context.closePath();
    context.stroke();

  try {
    // Call verifyQRInDatabase and destructure the result to get verified status and qrcode_id
    const { verified, qrcode_id } = await this.verifyQRInDatabase(userId);

    if (!verified) {
      console.error("User details do not match or user not found in the database.");
      return;
    } else if (rawValue.startsWith("User ID:")) {
      setTimeout(async () => {
        this.qrcodeProcessed = true;
        const timestamp = new Date(); // Extract the timestamp and create a Date object
        const formattedTimestamp = this.formatDate(timestamp); // Format the timestamp

        // Pass userId and qrcode_id into setSETimestamp
        await this.setSETimestamp(this.taskId, formattedTimestamp, userId, qrcode_id);
        this.closeModal();
      }, 500);
    }
  } catch (error) {
    console.error("Error verifying user:", error);
  }
},
      // API call to verify the user exists and matches name and email
      async verifyQRInDatabase(userId) {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    try {
        const response = await axios.get(`/api/qrcodes/user/${userId}`, { headers });
        const user_qrcodes = response.data;

        // Find the first QR code where is_active is set to 1
        const activeQRCode = user_qrcodes.find(qr_code => qr_code.is_active === 1);

        if (activeQRCode) {
            return {
                verified: true,
                qrcode_id: activeQRCode.qrcode_id // Return qrcode_id if active QR code is found
            };
        } else {
            return {
                verified: false,
                qrcode_id: null // No active QR code found
            };
        }
    } catch (error) {
        console.error("Error fetching user from database:", error);
        return {
            verified: false,
            qrcode_id: null // Return null if there's an error
        };
    }
},
async setSETimestamp(taskId, formattedTimestamp, userId, qrcodeId) {
  if (this.logbookEntryUpdated) {
    console.log("Logbook entry already updated. Skipping...");
    return; // Prevent multiple updates
  }

  const logbookId = this.$route.params.logbookId;
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  try {
    const response = await axios.put(`/api/logbookentry/${logbookId}/${taskId}`, {
      se_so: formattedTimestamp,
      se_id: userId,     // Include the user_id if needed
      qrcode_id: qrcodeId, // Include qrcode_id in the payload
    }, { headers });
    
    console.log("Success in updating logbook entry:", response);

    this.logbookEntryUpdated = true;
    this.qrcodeProcessed = true;
    this.closeModal();
  } catch (error) {
    console.error("Error updating logbook entry:", error);
  }
},
  },
  created() {
    console.log('Component created');
  },
  mounted() {
    console.log('Component mounted');
  },
}
</script>


<style scoped>
button.m-1:hover {
  background-color: rgb(224, 224, 224);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
  background-color: #fff;
  max-width: 90%;
  min-height: 50%;
  min-width: 60%;
  max-height: 85vh;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
  z-index: 10000;
  margin-top: 60px;
}

video {
  height: 50vh;
}

.modal-content-QR {
  max-height: 70vh;
  overflow-y: auto;
}
</style>